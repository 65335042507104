import React, { useState } from 'react';
import axios from 'axios';

function StorySubmitter() {
    const [story, setStory] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleStoryChange = (event) => {
        setStory(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post('/process_jack', {
                story: story
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            if (response.data.pass_status) {
                setResponseMessage('Maximum Gregg Detected');
            } else {
                setResponseMessage('Most Ungregglike, Dude');
            }
        } catch (error) {
            console.error('Error submitting story:', error);
            setResponseMessage('Error submitting story');
        }
    };

    return (
        <div>
            <h2>Tell me a story</h2>
            <textarea value={story} onChange={handleStoryChange} />
            <button onClick={handleSubmit}>Submit Story</button>
            <p>{responseMessage}</p>
        </div>
    );
}

export default StorySubmitter;

